import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "./Image";

const InteriorDoors = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="interiorni_vrati/5.jpg"
              alt="Интериорна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="interiorni_vrati/2.jpg"
              alt="Интериорна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="interiorni_vrati/3.jpg"
              alt="Интериорна врата"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link
            className="btn btn-primary"
            to="/gallery/#interiorni_vrati"
            activeClassName="active"
          >
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div>
          Ние от Бисер Пласт Партнерс предлагаме ПВЦ (PVC) и алуминиеви интериорни врати с голямо
          разнообразие от орнаментни термопанели. Декоративните панели придават индивидуален
          характер на интериора, като в същото време са в хармония с екстериорните Ви врати. Освен
          със своята уникалност, те се характеризират и с лесна поддръжка, богат избор на цветова
          гама и добра термоизолация.
        </div>
      </div>
    </div>
  );
};

InteriorDoors.propTypes = {
  frontmatter: PropTypes.object,
};

InteriorDoors.defaultProps = {
  frontmatter: {},
};

export default InteriorDoors;
